import React from 'react';
import { Content, Heading, Kicker } from '../ConversionPanelStyles';

const DefaultConversion = ({
  conversionButton,
  heading,
  headingKicker,
  size,
  subhead,
  theme,
  variant,
}) => (
  <Content align="center">
    {headingKicker && <Kicker theme={theme}>{headingKicker}</Kicker>}
    {heading && (
      <Heading theme={theme} size={size} variant={variant}>
        {heading}
      </Heading>
    )}
    {subhead && (
      <div dangerouslySetInnerHTML={{ __html: subhead }} theme={theme} />
    )}
    {conversionButton}
  </Content>
);

export default DefaultConversion;
